/*=================
main
=================*/
#form_check {
	position: absolute;
	left: 0;
	top: 0;
	width: 0;
	visibility: hidden;
	opacity: 0;
	clip: rect(0, 0, 0, 0);
	transition: all 4s ease-in-out;
}

.slider_contacts,
.top_slider {
	overflow: hidden;
	background-color: #302f36;
	background-image: linear-gradient(156deg, #47454f, #302f36);
}

.top_slider_wrap {
	position: relative;
	padding-top: 76%;
}

.swiper_parallax {
	position: absolute;
	top: 0;
	right: 0;
	display: inline-block;
	max-width: 100vw;
	width: 1320px;
	padding-bottom: 98px;

	.swiper-button-next,
	.swiper-button-prev {
		top: auto;
		bottom: 0;
		right: 0;
		width: 64px;
		height: 64px;
		border-radius: 50%;
		opacity: 0.6;
		background-color: rgba(255, 255, 255, 0.11);
		background-size: 16px;
		transition: all 0.3s linear;

		&:hover {
			opacity: 1;
		}

		&.swiper-button-disabled {
			opacity: 0.2;
		}
	}

	.swiper-button-prev {
		right: 88px;
		left: auto;
	}
}

.top_slider_descr {
	position: absolute;
	z-index: 2;
	bottom: 0;
	left: 0;
	max-width: 610px;
	padding-bottom: 52px;
	background-color: #fff;

	&::before {
		content: "";
		position: absolute;
		top: -37px;
		right: -264px;
		height: 200%;
		width: 200vw;
		background-color: #fff;
		z-index: -1;
	}

	.text {
		margin-top: 10px;
		margin-bottom: 58px;
		line-height: 1.49;
		letter-spacing: -0.3px;
	}

	.btn:not(:last-of-type) {
		margin-right: 16px;
	}
}

.text_right,
.text_left {
	overflow: hidden;

	&.p70 {
		.text_wrap_content {
			width: 34.2%;
			padding: 120px 0px 100px 48px;

			.title {
				font-size: 44px;
			}

			.text {
				p {
					font-size: 24px;
					line-height: 1.62;
				}
			}
		}
	}
}

.more {
	display: inline-block;
	padding-top: 18px;
	font-size: 20px;
	font-weight: 500;
	letter-spacing: -0.2px;
	color: #ee132b;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}

	i {
		vertical-align: middle;
		font-size: 22px;
		margin-left: 6px;
	}
}

.text_right {
	&.p70 {
		.text_wrap_content {
			&::after {
				content: "";
				position: absolute;
				top: calc(50% - 15px);
				right: 100%;
				display: inline-block;
				width: 0;
				height: 0;
				border: 0 solid transparent;
				border-top-width: 28px;
				border-bottom-width: 28px;
				border-right: 28px solid #fff;
				z-index: 2;
			}
		}
	}
}

.text_left {
	&.p70 {
		.text_wrap_content {
			padding: 120px 50px 100px 14px;

			&::after {
				content: "";
				position: absolute;
				top: calc(50% - 15px);
				left: 100%;
				display: inline-block;
				width: 0;
				height: 0;
				border: 0 solid transparent;
				border-bottom-width: 30px;
				border-top-width: 30px;
				border-left: 30px solid #ffffff;
				z-index: 2;
			}
		}
	}

	.text_wrap_content {
		padding-left: 0;
		padding-right: 82px;
	}

	.text_img img {
		right: auto;
		left: 0;
	}
}

.text_wrap {
	display: flex;
}

.text_img {
	position: relative;
	width: 100%;
	font-size: 0;

	img {
		min-width: 100%;
		min-height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		max-width: none;
	}
}

.text_wrap_content {
	position: relative;
	width: 50%;
	flex-shrink: 0;
	padding: 100px 0px 82px 78px;

	.title {}

	.text {
		padding-top: 20px;
		line-height: 1.47;
		letter-spacing: -0.4px;

		p {
			margin-top: 0;
			font-size: 30px;
		}

		ul {
			margin-top: 0;
		}
	}
}

.cta {
	position: relative;
	padding-top: 12%;
	padding-bottom: 12%;
	color: #fff;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	background-attachment: fixed;
	text-align: center;

	&::after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		display: inline-block;
		width: 100%;
		height: 100%;
		background-color: rgba(48, 47, 54, 0.65);
	}

	.title {
		position: relative;
		z-index: 2;
	}
}

.cta_descr {
	position: relative;
	z-index: 2;
	display: inline-block;
	margin-top: 6px;
	color: inherit;
	font-size: 32px;
	letter-spacing: -0.6px;
}

.main_materials {
	padding-top: 42px;
	padding-bottom: 40px;
	color: #fff;
	background-image: linear-gradient(127deg, #47454f, #302f36);
	text-align: center;
}

.grid {
	padding: 110px 20px 60px;
	margin: 0 auto;
	max-width: 1380px;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	background-image: url(../img/materials_main.png);
	background-repeat: no-repeat;
	background-position: center 38px;

	&:after {
		content: "";
		display: block;
		clear: both;
	}

	.swiper-wrapper {
		justify-content: center;
		flex-wrap: wrap;
	}
}

.grid--item {
	position: relative;
	margin-top: -4.8%;
	margin-right: 4px;
	margin-left: 4px;
	width: calc(20% - 8px);
	transition: all 0.5s;
	overflow: hidden;
	-webkit-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
	clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
	-webkit-shape-outside: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);

	&:before {
		display: block;
		padding-top: 112.5%;
		content: "";
	}

	&:nth-child(1),
	&:nth-child(10) {
		margin-left: 10.3%;
	}

	&:nth-child(4),
	&:last-of-type {
		margin-right: auto;
	}

	.img {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-position: center center;
		background-size: cover;
		overflow: hidden;
		-webkit-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
		clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
		transition: transform 0.5s;
		transition-delay: 0.1s;

		&:before,
		&:after {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			content: "";
			opacity: 0;
			transition: opacity 0.5s;
		}

		&:after {
			background: linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.2));
		}
	}

	h3 {
		margin-top: 4px;
		margin-bottom: 12px;
		font-size: 24px;
		font-weight: 700;
	}
}

.grid-container {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	opacity: 0;
	text-align: center;
	color: white;
	will-change: transform;
	backface-visibility: hidden;
	transform: translate(-50%, -50%) scale(0.9);
	transition: all 0.5s;
	-webkit-shape-outside: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
	shape-outside: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
}

.grid--item:hover {

	.img:after,
	.grid-container {
		opacity: 1;
	}

	.img {
		transform: scale(1.2);
	}

	.grid-container {
		transform: translate(-50%, -50%) scale(1);
	}
}

.partners {
	padding-top: 42px;
	padding-bottom: 62px;
	text-align: center;
}

.partners_wrap {
	display: flex;
	flex-wrap: wrap;
	margin-top: 30px;
}

.partners_case {
	flex-basis: calc(25% - 18px);
	margin-right: 24px;
	min-height: 184px;
	display: flex;
	padding: 20px;

	&:hover {
		img {
			transform: scale(1);
		}
	}

	&:last-of-type,
	&:nth-of-type(4n) {
		margin-right: 0;
	}

	img {
		margin: auto;
		transition: all 0.3s;
		transform: scale(0.8);
	}
}

.main_video {
	position: relative;
	width: 100%;
	height: 0;
	padding-bottom: 38.84%;
	overflow: hidden;

	video {
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		transform: translate3d(0, -50%, 0);
	}
}

.main_contacts {
	padding-bottom: 40px;

	>.title {
		position: relative;
		padding-top: 88px;
		padding-bottom: 75px;
		margin-bottom: 97px;
		background-image: url(../img/contact_title_bg.png);
		background-repeat: no-repeat;
		background-position: center top;
		background-size: cover;
		color: #fff;
		text-align: center;
		z-index: 1;

		&:after {
			content: "";
			position: absolute;
			top: 100%;
			left: calc(50% - 20px);
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 17px 20px 0 20px;
			border-color: #3e3e44 transparent transparent transparent;
		}
	}
}

.container {}

.main_contacts_wrap {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.main_contacts_form {
	width: 50%;
	flex-shrink: 0;
	padding-top: 12px;
}

.main_form {
	max-width: 480px;
	padding: 2px;

	.label:first-of-type {
		max-width: 191px;
	}

	.label:nth-of-type(2) {
		max-width: 260px;
		float: right;
	}
}

form {
	[type="submit"] {
		margin-top: -3px;
		padding: 18px 15px;
		font-weight: 500;
		font-size: 20px;
		color: #fff;
		border: none;
		border-radius: 0px;
		background-color: #ee132b;
		box-shadow: 0 16px 60px -16px rgb(169, 63, 93);
		cursor: pointer;

		&:hover {
			background-color: #ff3c53;
		}

		&:focus {
			box-shadow: 0 0px 0px 0px rgb(169, 63, 93);
		}
	}

	textarea,
	input {
		width: 100%;
		padding: 15px 14px 17px;
		appearance: none;
		border-radius: 4px;
		border: none;
		box-shadow: 0 0 0 1px #cfcfcf;
		font-size: 16px;
		outline: none;
		line-height: 1;
		transition: all 0.3s linear;

		&:focus {
			box-shadow: 0 0 0 2px #3453a8;

			+.label_text {
				color: #3453a8;
			}
		}
	}

	textarea {
		min-height: 188px;
		resize: none;
	}

	input[type="checkbox"] {
		border-radius: 0;
	}
}

.label {
	position: relative;
	display: inline-block;
	width: 100%;
	margin-bottom: 28px;
	font-size: 0;
}

.label_text {
	position: absolute;
	top: -10px;
	left: 10px;
	padding-left: 5px;
	padding-right: 6px;
	display: inline-block;
	font-size: 12px;
	background-color: #fff;
	transition: color 0.3s linear;
}

.main_contacts_map {
	width: 100%;
	margin-right: -40px;
	padding-left: 45px;

	.title {
		font-size: 28px;
	}
}

#map {
	margin-top: 9px;
	margin-right: 0;
	width: 100%;
	height: 331px;
}

.contacts_info {
	padding-top: 21px;

	.case {
		display: inline-block;
		margin-bottom: 14px;
		margin-right: 18px;
		vertical-align: middle;
		font-size: 18px;
		color: rgba(48, 48, 54, 0.5);
		letter-spacing: -0.2px;
		text-decoration: none;

		i {
			display: inline-block;
			vertical-align: top;
			color: #ee132b;
			font-size: 20px;
		}
	}

	.addres {
		font-family: Raleway, sans-serif;
		font-size: 20px;
	}

	a {
		margin-left: 2px;
		padding-left: 24px;
		background-repeat: no-repeat;
		background-position: left center;

		i {
			margin-right: 4px;
			margin-left: -16px;
		}

		&[href^="tel:38050"],
		&[href^="tel:38095"],
		&[href^="tel:38099"],
		&[href^="tel:38066"] {
			background-image: url(../img/Vodafone_logo.svg);
		}

		&[href^="tel:38063"],
		&[href^="tel:38093"] {
			background-image: url(../img/Lifecell_logo.svg);
		}

		&[href^="tel:38039"],
		&[href^="tel:38067"],
		&[href^="tel:38096"],
		&[href^="tel:38097"],
		&[href^="tel:38098"],
		&[href^="tel:38068"] {
			background-image: url(../img/Kyivstar_logo.svg);
		}
	}

	a:hover {
		text-decoration: underline;
	}
}

.seo_text_wrap {
	padding-top: 40px;
	padding-bottom: 12px;
	text-align: center;
}

.seo_text {
	position: relative;
	height: 250px;
	max-width: 1058px;
	margin-left: auto;
	margin-right: auto;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 1px;
	padding-bottom: 1px;
	overflow: hidden;
	font-size: 20px;
	line-height: 1.65;
	text-align: left;
	transition: height 0.3s linear;

	&.open {
		&::after {
			height: 0;
		}

		+.seo_text_toggle {
			transform: rotate(-90deg) scale(-1);
		}
	}

	&::after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 0;
		display: inline-block;
		width: 100%;
		height: 100%;
		background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0.3), #ffffff);
		transition: height 0.3s linear;
	}
}

.seo_text_toggle {
	appearance: none;
	padding: 10px;
	background-color: transparent;
	border: none;
	outline: none;
	color: #303036;
	transform: rotate(-90deg) scale(1);
	cursor: pointer;
	font-size: 0;
	transition: transform 0.3s linear;

	i {
		font-size: 30px;
	}
}

.product {
	p {
		margin: 0;
	}

	&__tab {
		overflow: hidden;
		padding-bottom: 50px;
	}

	&__header {
		display: flex;
		justify-content: space-between;
		margin-bottom: 40px;
	}

	&__header_item {
		position: relative;
		width: 50%;
		height: 422px;
		font-size: 57px;
		color: #fff;
		background-size: cover;
		background-position: 50% 50%;
		background-repeat: no-repeat;
		cursor: pointer;

		&.active {
			&:after {
				position: absolute;
				content: "";
				width: 52%;
				height: 22px;
				left: -3%;
				bottom: 0;
				background-color: #fff;
				transform: skewX(45deg);
			}

			&:before {
				position: absolute;
				content: "";
				width: 52%;
				height: 22px;
				right: -3%;
				bottom: 0;
				background-color: #fff;
				z-index: 10;
				transform: skewX(-45deg);
			}
		}

		&:after {
			position: absolute;
			content: "";
			width: 100%;
			height: 22px;
			left: 0;
			bottom: 0;
			background-color: #fff;
		}

		.overflow {
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			content: "";
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			background-color: rgba(0, 0, 0, 0.64);
			transition: background-color 0.34s ease-in;
		}

		&:hover {
			.overflow {
				transition: background-color 0.3s ease-in-out;
				background-color: rgba(0, 0, 0, 0.44);
			}
		}
	}

	&__content {
		position: absolute;
		left: -9999px;
		top: -9999px;
		visibility: hidden;
		opacity: 0;
		transition: visibility .2s;

		&.active {
			position: relative;
			left: 0;
			top: 0;
			display: flex;
			justify-content: space-between;
			visibility: visible;
			opacity: 1;
			transition: visibility .2s;
		}
	}

	&__filter--btn {
		display: none;
		width: 278px;
		max-width: 100%;
		padding: 14px 0;
		margin: 16px auto;
		font-size: 14px;
		font-weight: 500;
		color: #3453a8;
		text-align: center;
		border: solid 0.5px #f2f2f2;
		border-radius: 3px;
		background-color: #fff;
		cursor: pointer;
		text-decoration: none;

		i {
			margin-right: 8px;
		}
	}

	&__filter {
		margin-right: 23px;
		width: 278px;
		flex-shrink: 0;

		&--title {
			display: none;
			padding: 16px;
			margin-bottom: 24px;
			font-family: "Avenir Next", serif;
			font-size: 20px;
			font-weight: 700;
			letter-spacing: -0.2px;
			color: #3b3b41;
			background-color: #f7f7f7;
			cursor: pointer;

			i {
				cursor: pointer;
			}
		}

		.filter-item {
			margin-bottom: 16px;

			p {
				margin: 0;
				padding: 0;
			}

			label {
				width: 100%;
				padding: 11px 0;
				display: flex;
				align-items: center;
				position: relative;
				cursor: pointer;

				&:hover {
					background-color: #f7f7f7;
				}

				.checkbox {
					width: 14px;
					height: 14px;
					margin-left: 17px;
					border: none;
					cursor: pointer;

					&:focus {
						border: none;
					}

					&:checked {
						transition: background-color .4s;
						background-color: #3453a8;
						border: 1px solid transparent;
					}

					&:checked+i {
						visibility: visible;
						opacity: 1;
					}
				}

				span {
					padding-left: 15px;
					font-family: "Raleway", sans-serif;
					font-size: 16px;
					font-weight: 500;
					color: #303036;
				}

				i {
					position: absolute;
					visibility: hidden;
					opacity: 0;
					left: 17px;
					font-size: 14px;
					color: #fff;
				}
			}
		}

		h3 {
			position: relative;
			display: block;
			padding-bottom: 8px;
			margin-bottom: 16px;
			font-size: 20px;
			font-weight: 700;
			color: #3b3b41;

			&:after {
				position: absolute;
				content: "";
				left: 0;
				top: 100%;
				width: 100%;
				height: 1px;
				background-image: linear-gradient(to left, rgba(48, 48, 54, 0.15), rgba(48, 48, 54, 0.32) 54%, rgba(48, 48, 54, 0.15));
			}
		}

		p {
			padding: 11px 0;
		}

		input[type="submit"] {
			width: 278px;
			max-width: 100%;
			padding: 12px 0;
			font-size: 18px;
			color: #fff;
			border: none;
			background-color: #3453a8;
			box-shadow: 0 20px 20px -15px rgba(52, 83, 168, 0.42);
			cursor: pointer;
		}
	}

	&__list {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-start;

		.item {
			position: relative;
			margin-right: 26px;
			margin-bottom: 22px;
			width: 278px;
			text-align: center;

			&:nth-child(3n) {
				margin-right: 0;
			}

			p {
				font-size: 18px;
				font-weight: 700;
			}

			.item-img {
				position: relative;
				width: 278px;
				max-width: 100%;
				height: 278px;
				margin-bottom: 11px;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}

				&:hover {
					&:after {
						position: absolute;
						content: "";
						width: 100%;
						height: 100%;
						left: 0;
						top: 0;
						background-color: rgba(0, 0, 0, 0.75);
						transition: height 0.4s;
					}

					.item-order {
						visibility: visible;
						opacity: 1;
						transform: translate(-50%, -50%);
						transition: transform 0.6s ease, opacity 0.5s;
					}
				}

				.item-order {
					position: absolute;
					width: 80%;
					left: 50%;
					top: 50%;
					padding: 12px 0;
					font-size: 18px;
					font-weight: 700;
					color: #fff;
					letter-spacing: -0.2px;
					-webkit-backdrop-filter: blur(1.6px);
					backdrop-filter: blur(1.6px);
					background-color: rgba(134, 134, 134, 0.24);
					cursor: pointer;
					text-decoration: none;
					transform: translate(-50%, 100%);
					visibility: hidden;
					opacity: 0;
					z-index: 10;
				}
			}
		}
	}

	&__paggination {
		width: 100%;
		display: flex;
		justify-content: center;
		margin: 32px 0 40px;

		ul {
			list-style-type: none;
			padding-left: 0;

			li {
				display: inline-block;
				margin-right: 26px;

				&:last-child {
					margin-right: 0;
				}
			}

			a {
				padding: 0 5px;
				font-family: "Avenir Next", serif;
				font-size: 24px;
				color: #3b3b41;
				letter-spacing: -0.2px;
				text-decoration: none;
				border-bottom: 2px solid transparent;


				&:hover {
					border-bottom: 2px solid #3453a8;
				}
			}

			span {
				padding: 0 5px;
				font-family: "Avenir Next", serif;
				font-size: 24px;
				color: #3b3b41;
				letter-spacing: -0.2px;
				text-decoration: none;
				border-bottom: 2px solid transparent;

				&.current,
				&:hover {
					border-bottom: 2px solid #3453a8;
				}
			}
		}
	}
}

.modal {
	max-width: 480px;
	padding: 20px 32px 35px;
	background-color: #ffffff;
	box-shadow: 0 2px 35px 0 rgba(130, 130, 130, 0.5);
}

.modal_title {
	margin: 0;
	text-align: center;
	font-size: 24px;
}

.modal_descr {
	margin-bottom: 16px;
	font-family: Raleway, sans-serif;
	font-size: 14px;
	line-height: 1.36;
}

.fancybox-bg {
	background-color: rgba(255, 255, 255, 0.9);
}

.slider_contacts {
	.title {
		font-size: 50px;
	}

	.text {
		font-size: 30px;
	}
}

.slider_phones {
	max-width: 560px;
	list-style: none;
	padding-left: 0;
	margin: 0;
	display: flex;
	align-items: flex-start;
	flex-wrap: wrap;
	justify-content: space-between;

	li {}

	a {
		padding-left: 40px;
		font-size: 24px;
		line-height: 1.5;
		color: #303036;
		text-decoration: none;
		background-position: left center;
		background-repeat: no-repeat;
		background-size: 24px;

		&:hover {
			text-decoration: underline;
		}

		&[href^="tel:38044"] {
			background-image: url(../img/phone-call-button.svg);
		}

		&[href^="tel:38050"],
		&[href^="tel:38095"],
		&[href^="tel:38099"],
		&[href^="tel:38066"] {
			background-image: url(../img/Vodafone_logo.svg);
		}

		&[href^="tel:38063"],
		&[href^="tel:38093"] {
			background-image: url(../img/Lifecell_logo.svg);
		}

		&[href^="tel:38039"],
		&[href^="tel:38067"],
		&[href^="tel:38096"],
		&[href^="tel:38097"],
		&[href^="tel:38098"],
		&[href^="tel:38068"] {
			background-image: url(../img/Kyivstar_logo.svg);
		}
	}
}

.contacts_form {
	.title {}

	.main_contacts_map {
		padding-left: 0;
	}

	.main_contacts_wrap {
		align-items: stretch;
	}

	.main_contacts_map {
		position: relative;
	}

	#map {
		position: absolute !important;
		top: 0;
		right: 0;
		width: 50vw;
		height: 100%;
	}

	.main_contacts_form {
		padding-left: 120px;
		padding-top: 55px;
		padding-bottom: 60px;
	}
}

.contacts_form_descr {
	margin-bottom: 60px;
}

.project_top {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	color: #fff;
	text-align: center;
	height: 40vw;
	max-height: 560px;

	.title {
		font-size: 48px;
	}

	.descr {
		font-size: 18px;
	}
}

.project_content {
	max-width: 740px;
	padding-left: 20px;
	padding-right: 20px;
	margin-left: auto;
	margin-right: auto;
	padding-bottom: 40px;
	font-size: 21px;
	line-height: 1.57;

	h2 {
		font-size: 30px;
		margin-bottom: 16px;
	}

	h3 {
		font-size: 28px;
		margin-bottom: 14px;
	}

	h4 {
		font-size: 26px;
		margin-bottom: 12px;
	}
}

.inspiration_top,
.portfolio_top {
	position: relative;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	background-attachment: fixed;
	color: #fff;
	display: flex;
	align-items: center;
	height: 53vw;
	max-height: 762px;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		display: inline-block;
		width: 100%;
		height: 100%;
		background-color: rgba(48, 47, 54, 0.37);
	}

	.container {
		width: 100%;
	}

	.title {
		position: relative;
		margin-bottom: 120px;
		font-size: 64px;
		line-height: 1.17;
	}

	.subtitle {
		position: relative;
		line-height: 1.49;
		letter-spacing: -0.3px;
	}
}

.inspiration_top {
	.title {
		margin-bottom: 0;
	}
}

.about_top {
	position: relative;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	background-attachment: fixed;
	color: #fff;
	display: flex;
	align-items: flex-end;
	height: 53vw;
	max-height: 762px;
	padding-bottom: 64px;

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		display: inline-block;
		width: 100%;
		height: 100%;
		background-color: rgba(48, 47, 54, 0.27);
	}

	.container {
		position: relative;
		width: 100%;
	}

	.sup_title {
		margin-bottom: 0;
		font-family: "Avenir Next";
		font-size: 20px;
		font-weight: 300;
		line-height: 1.4;
		letter-spacing: -0.3px;
	}

	.title {
		max-width: 650px;
		font-size: 64px;
		line-height: 1.09;
	}

	.descr {
		max-width: 600px;
		font-size: 30px;
		line-height: 1.27;
		letter-spacing: -0.5px;
	}
}

.about_text {
	.text_wrap_content {
		padding: 155px 20px 135px 108px;
	}

	.title {
		font-size: 44px;
	}

	.position {
		font-size: 20px;
		line-height: 1.94;
		letter-spacing: -0.2px;
		color: #9997a4;

		p {
			margin-top: 0;
			margin-bottom: 12px;
		}
	}

	.text {
		position: relative;
		padding-top: 0;

		&:before {
			content: '"';
			position: absolute;
			top: -20px;
			left: -90px;
			display: inline-block;
			font-size: 288px;
			line-height: 1;
			letter-spacing: -0.5px;
			font-style: italic;
			font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
			color: rgba(177, 177, 177, 0.11);
			z-index: -1;
		}

		p {
			font-size: 24px;
			line-height: 1.6;
			letter-spacing: -0.4px;
		}
	}
}

.text_left.about_text {
	.text_wrap_content {
		padding: 155px 95px 135px 34px;
	}
}

.why_we {
	padding-top: 48px;
	padding-bottom: 36px;
	text-align: center;
	background-color: rgba(247, 247, 247, 0.5);

	.container {}

	.title {}
}

.why_we_content {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	max-width: 1000px;
	margin-left: auto;
	margin-right: auto;
	padding-top: 56px;
}

.why_we_case {
	width: 30%;
	margin-bottom: 44px;

	.img {
		display: flex;
		width: 123px;
		height: 123px;
		margin-left: auto;
		margin-right: auto;
		border-radius: 35px 0 35px 0;
		background-color: #ffffff;
		box-shadow: 0 2px 170px 0 rgba(239, 239, 239, 0.5);

		img {
			margin: auto;
		}
	}

	.text {
		margin-top: 10px;
		font-size: 24px;
		font-weight: 700;
		line-height: 1.25;
	}
}

.inspiration_wrap {}

.inspiration_nav {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-left: auto;
	margin-right: auto;
	max-width: 986px;
	padding-top: 33px;
	padding-bottom: 30px;
}

.inspiration_nav_link {
	position: relative;
	padding: 10px 2px 9px;
	margin-left: 8px;
	margin-right: 8px;
	color: #a9a9a9;
	text-decoration: none;
	font-family: Raleway, sans-serif;
	overflow: hidden;

	&.active,
	&.hover {
		&:after {
			transform: translate3d(0, 0, 0);
		}
	}

	&.active {
		font-family: 'Avenir Next', sans-serif;
		font-weight: 700;
		color: #303036;
	}

	&.no_hover {
		&:after {
			transform: translate3d(-100%, 0, 0);
		}
	}

	&:after {
		content: "";
		position: absolute;
		left: 0;
		bottom: 0;
		width: 100%;
		height: 3px;
		background-color: #3453a8;
		transform: translate3d(-100%, 0, 0);
		transition: transform 0.3s linear;
	}
}

.inspiration_content {
	display: flex;
	flex-wrap: wrap;
}

.inspiration_case {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	width: 33.3333%;
	height: 50vw;
	max-height: 480px;
	padding: 40px 2% 40px 6.8%;
	color: #fff;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;

	&:nth-of-type(4n + 10) {
		width: 100%;
	}

	&:nth-of-type(1),
	&:nth-of-type(2),
	&:nth-of-type(9),
	&:nth-of-type(10) {
		width: 50%;
	}

	&:nth-of-type(3),
	&:nth-of-type(8) {
		width: 100%;
	}

	&:nth-of-type(4n + 10),
	&:nth-of-type(1),
	&:nth-of-type(2),
	&:nth-of-type(9),
	&:nth-of-type(10),
	&:nth-of-type(3),
	&:nth-of-type(8) {
		max-height: 720px;
	}

	&:hover {

		&:before,
		.text,
		.title,
		.link {
			opacity: 1;
			visibility: visible;
		}
	}

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		display: inline-block;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.68);
		z-index: 1;
	}

	.title {
		font-size: 50px;
	}

	.text {
		max-width: 460px;
		margin-top: 14px;
		font-size: 20px;
		line-height: 1.62;
	}

	.link {
		color: #fff;
		text-decoration: none;

		&:hover {
			text-decoration: underline;
		}

		i {
			display: inline-block;
			margin-left: 6px;
			font-size: 20px;
			vertical-align: middle;
		}
	}

	&:before,
	.text,
	.title,
	.link {
		opacity: 0;
		visibility: hidden;
		transition: all 0.3s linear;
	}

	.text,
	.title,
	.link {
		position: relative;
		z-index: 2;
	}
}

.inspiration_cta {
	display: flex;
	justify-content: space-between;
	align-items: center;
	max-width: 980px;
	margin-left: auto;
	margin-right: auto;
	padding-top: 70px;
	padding-bottom: 70px;
}

.inspiration_cta_left,
.inspiration_cta_right {
	width: 50%;
}

.inspiration_cta_left {
	padding-right: 16%;

	.text {
		line-height: 1.62;
		letter-spacing: -0.2px;
	}
}

.inspiration_cta_right {
	padding-left: 10px;
}

/* =================
END main
================= */