/*=================
header
=================*/
.header {
	overflow: hidden;
	position: relative;
}

.header_top {
	display: flex;
	align-items: stretch;
}

.logo {
	font-size: 0;
	padding-top: 18px;
}

.header_menu {
	margin-left: auto;
	margin-right: 56px;
	padding-top: 38px;

	.menu {
		list-style: none;
		margin: 0;
		padding-left: 0;
	}

	.menu-item {
		display: inline-block;

		&:not(:last-of-type) {
			margin-right: 18px;
		}
	}

	.current-menu-item {
		a {
			&:after {
				transform: translate3d(-60%, 0, 0);
			}
		}
	}

	a {
		position: relative;
		display: inline-block;
		padding-bottom: 5px;
		text-decoration: none;
		font-size: 14px;
		font-weight: 500;
		letter-spacing: 0.2px;
		color: #303036;
		overflow: hidden;

		&.hover {
			&:after {
				transform: translate3d(-60%, 0, 0);
			}
		}

		&.no_hover {
			&:after {
				transform: translate3d(-110%, 0, 0);
			}
		}

		&:after {
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			width: 200%;
			height: 3px;
			background-color: #3453a8;
			transform: translate3d(-110%, 0, 0);
			transition: transform 0.3s cubic-bezier(.27, 1.01, .4, 1.55);
		}
	}
}

.mob_menu_btn {
	position: relative;
	display: none;
	width: 64px;
	margin-right: -20px;
	padding-top: 18px;
	padding-bottom: 0px;
	background-color: #302f36;
	color: #fff;
	text-align: center;
	text-decoration: none;

	&:after,
	&:before {
		content: '';
		position: absolute;
		opacity: 0;
		visibility: hidden;
		transition: all 0.3s linear;
	}

	&:before {
		content: 'Меню';
		top: 0;
		right: 0;
		display: flex;
		align-items: center;
		width: 100vw;
		height: 100%;
		padding-left: 40px;
		background-color: #302f36;
		font-size: 20px;
		font-weight: 700;
		color: #ffffff;
	}

	&:after {
		top: 14px;
		right: 15px;
		display: inline-block;
		width: 32px;
		height: 32px;
		background-image: url(../img/close.svg);
		background-repeat: no-repeat;
		background-position: center;
		z-index: 2;
	}

	&.open {

		&:after,
		&:before {
			opacity: 1;
			visibility: visible;
		}
	}

	.text {
		display: block;
		font-size: 10px;
		line-height: 0;
		letter-spacing: -0.9px;
		font-family: Raleway, sans-serif;
	}

	i {
		font-size: 32px;
	}
}

.header_phone {
	position: relative;
	min-width: 176px;
	padding-left: 10px;
	padding-top: 30px;
	padding-bottom: 23px;
	color: #fff;
	text-align: right;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		display: inline-block;
		width: 100vw;
		height: 100%;
		//background-color: #302f36;
		background-image: linear-gradient(156deg, #47454f, #302f36);
		z-index: -1;
	}

	.material-icons {
		font-size: 14px;
		margin-right: 4px;
	}

	a {
		font-size: 14px;
		font-weight: 500;
		letter-spacing: 0.2px;
		text-decoration: none;
		color: inherit;
	}
}

/*=================
END header
=================*/