// font-face&Mixins-font
@import "fonts";
//
// Icons
@import "icons";
//
// Normalize
@import "normalize";
//
// Icons
@import "icons";

//
*,
*:before,
*:after {
	box-sizing: border-box; //z-index: 1;
	//backface-visibility: hidden;
}

html,
body {
	font-size: 24px;
	color: #302f36;
}

body.no_scroll {
	overflow: hidden;
}

html,
body,
a,
button,
input,
textarea {
	font-family: 'Avenir Next', sans-serif;
}

p {
	font-family: 'Raleway', sans-serif;
}

a {
	color: #3453a8;

	&:hover {
		text-decoration: none;
	}
}

img {
	max-width: 100%;
	height: auto;
}

.container {
	max-width: 1240px;
	padding-left: 20px;
	padding-right: 20px;
	margin-left: auto;
	margin-right: auto;
}

.title {
	margin: 0;
	font-size: 40px;
	font-weight: 700;
	line-height: 1.2;
	letter-spacing: 0.3px;
}

.title_large {
	font-size: 48px;
}

.btn {
	display: inline-block;
	min-width: 288px;
	padding: 21px 20px 21px;
	border-radius: 0;
	border: none;
	color: #fff;
	background-color: #303036;
	font-size: 20px;
	line-height: 1.2;
	font-weight: 500;
	letter-spacing: 0.2px;
	box-shadow: 0 18px 60px -18px rgb(9, 21, 33);
	appearance: none;
	text-decoration: none;
	text-align: center;
	vertical-align: top;
	transition: all 0.3s linear;

	&:hover {
		background-color: #3f3f44;
	}
}

.btn_red {
	background-color: #ee132b;
	box-shadow: 0 18px 60px -18px rgb(169, 63, 93);

	&:hover {
		background-color: #ff3c53;
	}
}

.btn_large {
	min-width: 333px;
	padding: 25px 20px 26px;
}

.btn_small {
	box-shadow: none;
	min-width: 184px;
	padding: 13px 20px 13px;
	font-size: 18px;
	border: none;
	background-color: rgba(212, 205, 205, 0.24);

	&:hover {
		background-color: rgba(63, 63, 68, 0.4);
	}
}

@import "header";
@import "main";
@import "footer";
@import "media-queries";