/*================= *media-queries =================*/
@media screen and (max-width: 1400px) {
	.grid {
		padding-top: 8.4vw;
		background-position: center 3vw;
		background-size: 97vw;
	}

	.main_contacts>.title {
		background-size: auto;
	}

	.main_contacts_map {
		margin-right: 0;
	}

	.contacts_info .case {
		font-size: 16px;
	}
}

@media screen and (max-width: 1220px) {
	.contacts_form .main_contacts_form {
		padding-left: 50px;
	}

	.inspiration_case {
		width: 50%;
		max-height: none !important;
	}

	&:nth-of-type(4n + 10) {
		width: 50%;
	}

	&:nth-of-type(3n + 8),
	.inspiration_case:nth-of-type(3),
	.inspiration_case:nth-of-type(6) {
		width: 100%;
	}

	.inspiration_case .title {
		font-size: 32px !important;
	}

	.inspiration_case .text {
		font-size: 16px;
	}

	.product {
		&__header_item {
			height: 300px;
			font-size: 46px;
		}

		&__list {
			justify-content: center;

			.item {
				&:nth-child(3n) {
					margin-right: 26px;
				}
			}
		}
	}
}

@media screen and (max-width: 1100px) {

	.swiper-button-prev,
	.swiper-button-next {
		display: none;
	}

	.header {
		overflow: visible;
	}

	.header_menu {
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		height: 0;
		margin: 0;
		padding: 0;
		overflow: hidden;
		background-color: #fff;
		text-align: center;
		z-index: 20;
		transition: height 0.3s linear;

		.menu {
			padding-top: 16px;
			padding-bottom: 10px;
		}
	}

	.header_phone {
		margin-left: auto;
		color: #302f36;
		padding-top: 23px;
		padding-bottom: 16px;
		padding-right: 8px;

		&:before {
			display: none;
		}
	}

	.mob_menu_btn {
		display: block;
	}

	.logo {
		padding-top: 18px;
	}

	.footer_col {
		width: 25%;
	}

	.top_slider_descr::before {
		top: -10px;
		right: -10px;
	}

	.top_slider_wrap {
		padding-top: 0;
	}

	.top_slider_descr,
	.swiper_parallax {
		position: relative;
	}

	.swiper_parallax {
		padding-bottom: 0;
	}

	.top_slider_descr {
		margin-top: -100px;
	}

	.text_left.about_text .text_wrap_content,
	.about_text .text_wrap_content,
	.text_wrap_content {
		padding: 40px 0px 40px 30px;
	}

	.main_form .label:nth-of-type(2) {
		max-width: 222px;
	}

	.grid {
		display: block;
		background-image: none;
		overflow: hidden;
		position: relative;
		padding-top: 30px;

		.swiper-slide {
			.grid--item {
				width: 260px;
				transform: scale(1);
			}
		}

		&.swiper-container-horizontal {
			.swiper-slide {
				width: auto;

				.grid--item {
					transform: scaleX(0.9) scaleY(0.72);

					.img:after,
					.grid-container {
						opacity: 0;
						visibility: hidden;
					}

					.img {
						transform: scale(1);
					}

					.grid-container {
						transform: translate(-50%, -50%) scale(0.9);
					}
				}
			}

			.swiper-slide-active {
				z-index: 2;

				.grid--item {
					transform: scaleX(1) scaleY(1);

					.img:after,
					.grid-container {
						opacity: 1;
						visibility: visible;
					}

					.img {
						transform: scale(1.2);
					}

					.grid-container {
						transform: translate(-50%, -50%) scale(1);
					}
				}
			}
		}
	}

	.grid--item {
		width: 260px;
		flex-shrink: 0;
	}

	.grid--item:nth-child(1),
	.grid--item:nth-child(10) {
		margin-left: 4px;
	}

	.grid--item:nth-child(4),
	.grid--item:last-of-type {
		margin-right: 4px;
	}

	.swiper-slide .grid--item {
		margin-left: 0;
		margin-right: 0;
	}

	.grid .swiper-wrapper {
		flex-wrap: nowrap;
		justify-content: flex-start;
		align-items: center;
		min-height: 300px;
	}

	.swiper-pagination-bullet {
		width: 10px;
		height: 10px;
		background-color: #fff;
		outline: none;
	}

	.swiper-pagination-bullet-active {
		background-color: #fff;
	}

	.contacts_form .main_contacts_wrap {
		flex-direction: column;
	}

	.contacts_form #map {
		position: relative !important;
		top: 0;
		left: 0px;
		width: 100vw;
		height: 480px;
		margin-top: 0;
	}

	.contacts_form .main_contacts_form {
		padding-left: 0;
	}

	.about_top .title {
		font-size: 40px;
	}

	.about_top .sup_title {
		font-size: 16px;
	}

	.about_top .descr {
		font-size: 22px;
	}

	.about_top {
		padding-bottom: 24px;
	}

	.text_right.p70 .text_wrap_content,
	.text_left.p70 .text_wrap_content {
		width: 50%;
	}

	.inspiration_cta {
		max-width: 100%;
		padding-right: 20px;
		padding-left: 20px;
	}

	.inspiration_cta_left {
		padding-right: 4%;
	}
}

@media screen and (max-width: 900px) {

	.footer_email,
	.footer_menu,
	.contacts_info,
	.main_contacts_map .title {
		display: none;
	}

	.footer {
		.container {
			padding-left: 0;
			padding-right: 0;
		}
	}

	.footer_bottom {
		position: relative;
		margin-top: 0;
		border-top: none;

		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 1px;
			opacity: 0.17;
			background-image: linear-gradient(to left, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.44) 50%, rgba(255, 255, 255, 0.09));
		}
	}

	.footer_col {
		width: 100%;
		text-align: center;
	}

	.footer_col:last-of-type {
		display: flex;
		flex-direction: column;
		width: 100%;
		margin-left: 0;
	}

	.footer_logo {
		text-align: center;
	}

	.to_top {
		top: -26px;
	}

	.footer_phones {
		position: relative;
		padding-top: 16px;

		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 1px;
			opacity: 0.17;
			background-image: linear-gradient(to left, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.44) 50%, rgba(255, 255, 255, 0.09));
		}
	}

	.footer_phones li {
		width: 100%;
	}

	.footer_phones a {
		line-height: 2;
	}

	.footer_wrap {
		flex-direction: column;
		align-items: center;
		padding-top: 10px;
	}

	.footer_social {
		min-width: 110px;
		margin-left: auto;
		margin-right: auto;
	}

	.footer_col .title {
		font-size: 16px;
		line-height: 2.8;
	}

	.main_contacts_wrap {
		flex-direction: column-reverse;
	}

	.main_contacts>.title {
		margin-bottom: 0;
	}

	.main_contacts_map {
		padding-left: 0;
	}

	#map {
		width: 100vw;
		margin-left: -20px;
		margin-top: 0;
	}

	.main_contacts_form {
		padding-top: 25px;
		width: 100%;
	}

	.main_form {
		max-width: none;
	}

	.main_form .label:first-of-type,
	.main_form .label:nth-of-type(2) {
		max-width: 49%;
	}

	.seo_text {
		height: 200px;
		font-size: 16px;
	}

	.seo_text_wrap {
		padding-top: 1px;
	}

	.main_contacts {
		padding-bottom: 16px;
	}

	.inspiration_top .title,
	.portfolio_top .title {
		text-align: center;
		margin-bottom: 0;
		font-size: 24px;
	}

	.inspiration_top .subtitle {
		font-size: 14px;
		text-align: center;
	}

	.product {
		&__header_item {
			height: 300px;
			font-size: 38px;
		}
	}

	.footer_social a {
		width: 24px;
		height: 24px;
		padding: 6px;
		line-height: 0;
	}

	.footer_social a:after {
		position: static;
		background-size: contain;
	}
}

@media screen and (max-width: 768px) {
	.product {
		&__header {
			margin-bottom: 0;
		}

		&__header_item {
			background-image: none !important;
			background-size: 0;
			height: 46px;

			&.active {
				.overflow {
					background-color: #fff;

					&:after {
						position: absolute;
						content: '';
						width: 100%;
						height: 5px;
						top: 0;
						left: 0;
						background-color: #3453a8;
					}
				}
			}

			&:after,
			&:before {
				display: none;
			}

			.overflow {
				position: relative;
				font-size: 14px;
				color: #b5b5b5;
				background-color: #f3f3f3;
			}
		}

		&__content {
			flex-wrap: wrap;
		}

		&__filter--btn {
			display: flex;
			justify-content: center;
			align-items: center;
		}

		&__filter {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			background-color: #fff;
			margin-right: 0;
			z-index: 15;
			transform: translateX(-150%);
			transition: transform .4s ease-in-out;

			&.active {
				transition: transform .4s ease-in-out;
				transform: translateX(0);
			}

			&--title {
				position: relative;
				display: flex;
				align-items: center;
				justify-content: space-between;

				&:before {
					position: absolute;
					content: '';
					width: 100%;
					height: 100%;
					left: -100%;
					top: 0;
					background-color: #f7f7f7;
				}
			}
		}

		&__list {
			justify-content: space-between;

			.item {
				margin-right: 0;

				&:nth-child(3n) {
					margin-right: 0;
				}
			}
		}
	}

	.about_text .title,
	.title {
		font-size: 20px;
	}

	.title_large {
		font-size: 24px;
	}

	.btn,
	html,
	body {
		font-size: 14px;
	}

	.swiper_parallax {
		right: 20px;
	}

	.top_slider_descr {
		max-width: 80%;
		margin-top: -40px;
		padding-bottom: 60px;

		.text {
			margin-bottom: 24px;
		}

		.btn {
			min-width: 153px;
			padding: 12px 20px 12px;
		}
	}

	.top_slider_btns {
		position: absolute;
		bottom: 0;
		left: -20px;
		width: 100vw;
		padding-bottom: 18px;
		padding-left: 4px;
		padding-top: 20px;
		padding-right: 4px;
		white-space: nowrap;
		background-color: #fff;
	}

	.top_slider_descr .btn:not(:last-of-type) {
		margin-right: 2px;
	}

	.about_text .position,
	.cta_descr,
	.text_wrap_content .text p {
		font-size: 16px;
	}

	.partners_case {
		flex-basis: calc(50% - 10px);
		padding: 10px;
		margin-right: 20px;
	}

	.partners_case:nth-of-type(4n) {
		margin-right: 20px;
	}

	.partners_case:nth-of-type(2n) {
		margin-right: 0;
	}

	.grid {
		padding-top: 6vw;
	}

	.main_materials {
		padding-bottom: 0;

		.btn_large {
			min-width: auto;
			width: 100%;
		}
	}

	.about_top .sup_title {
		font-size: 12px;
	}

	.about_top .title {
		font-size: 24px;
	}

	.about_top .descr {
		font-size: 14px;
	}

	.about_top {
		height: 96vw;
	}

	.why_we_case {
		width: 50%;
	}

	.to_top {
		right: 20px;
		top: 50px;
		width: 67px;
		height: 67px;
		padding: 4px;
	}

	.project_top,
	.inspiration_top,
	.portfolio_top {
		height: 80vw;
	}

	.text_right.p70 .text_wrap_content,
	.text_left.p70 .text_wrap_content {
		padding: 40px 0px 40px 30px;
	}

	.text_right.p70 .text_wrap_content .title,
	.text_left.p70 .text_wrap_content .title {
		font-size: 20px;
	}

	.text_right.p70 .text_wrap_content .text p,
	.text_left.p70 .text_wrap_content .text p {
		font-size: 16px;
	}

	.more {
		font-size: 16px;
	}

	.project_top {
		padding-left: 20px;
		padding-right: 20px;
	}

	.project_top .title {
		font-size: 24px;
	}

	.project_top .descr {
		font-size: 14px;
	}

	.main_materials {
		.btn {
			font-size: 20px;
		}
	}

	.fancybox-slide--html .fancybox-content {
		vertical-align: bottom;
		margin-bottom: 48px;
	}

	.about_top,
	.inspiration_top,
	.portfolio_top,
	.cta {
		background-attachment: scroll;
	}

	.main_video {
		display: none;
	}

	.contacts_form .main_contacts_form {
		padding-top: 0;
	}

	.contacts_form_descr {
		display: none;
	}

	.main_contacts_form>.title,
	.main_contacts>.title {
		padding-top: 32px;
		padding-bottom: 32px;
		background-size: cover;
	}

	.main_contacts_form>.title {
		position: relative;
		margin-left: -20px;
		margin-right: -20px;
		margin-bottom: 30px;
		background-image: url(../img/contact_title_bg.png);
		background-repeat: no-repeat;
		background-position: center top;
		-webkit-background-size: cover;
		background-size: cover;
		color: #fff;
		text-align: center;
		z-index: 1;
	}

	.main_contacts_form>.title:after {
		content: "";
		position: absolute;
		top: 100%;
		left: calc(50% - 8px);
		width: 0;
		height: 0;
		border-style: solid;
		border-width: 8px 8px 0 8px;
		border-color: #3e3e44 transparent transparent transparent;
	}

	.main_contacts>.title:after {
		left: calc(50% - 8px);
		border-width: 8px 8px 0 8px;
	}

	#map {
		height: 480px;
	}

	.slider_contacts .top_slider_descr {
		padding-bottom: 20px;
	}

	.contacts_form {
		margin-top: -20px;
	}

	.main_materials .btn_small {
		font-size: 16px;
		min-width: 160px;
	}
}

@media screen and (max-width: 600px) {
	.logo {
		max-width: 100px;
	}

	.header_phone {
		min-width: auto;
		// padding-top: 12px;
		// padding-bottom: 2px;
	}

	.header_phone a,
	.header_phone .material-icons {
		font-size: 12px;
	}

	.product {
		&__list {
			justify-content: center;
		}
	}

	.text_wrap {
		flex-direction: column;
	}

	body.home {
		.text_wrap_content {
			z-index: 2;
			width: 100%;
			padding-left: 0;
			padding-top: 0;
			padding-bottom: 0;

			.title {
				padding-top: 18px;
				padding-bottom: 10px;
				color: #fff;
				text-align: center;
			}
		}

		.text_img img {
			min-width: 100vw;
			min-height: auto;
			height: 56px;
			right: auto;
			left: -20px;
			object-fit: cover;
		}

		.text_left {
			.text_wrap {
				flex-direction: column-reverse;
			}

			.text_wrap_content {
				padding-right: 0;
			}
		}
	}

	.cta_descr {
		br {
			display: none;
		}
	}

	.main_form .label:first-of-type,
	.main_form .label:nth-of-type(2) {
		max-width: 100%;
	}

	.about_text {
		.text_img {
			margin-left: -20px;
			width: 100vw;
			order: 0;

			&:after {
				content: '';
				position: absolute;
				right: calc(50% + 8px);
				bottom: 0;
				display: inline-block;
				width: 100%;
				height: 16px;
				transform: skewX(45deg);
				background-color: #fff;
			}

			&:before {
				content: '';
				position: absolute;
				left: calc(50% + 8px);
				bottom: 0;
				display: inline-block;
				width: 100%;
				height: 16px;
				transform: skewX(-45deg);
				background-color: #fff;
			}

			img {
				position: static;
				max-width: 100%;
			}
		}

		.text_wrap_content {
			order: 1;
		}
	}

	.text_left.about_text .text_wrap_content,
	.about_text .text_wrap_content {
		width: 100%;
		padding-left: 0;
		padding-top: 8px;
		padding-bottom: 0;
	}

	.why_we_case .text {
		font-size: 18px;
	}

	.text_right.p70 .text_wrap_content,
	.text_left.p70 .text_wrap_content {
		width: 100%;
		padding-left: 0;
		padding-top: 8px;
		padding-bottom: 0;
	}

	.p70 {
		.text_wrap_content {
			position: static;
		}

		.text_wrap {
			position: relative;
		}

		.more {
			width: 100vw;
			background-color: #ee132b;
			position: absolute;
			left: -20px;
			bottom: 0;
			text-align: center;
			color: #fff;
			padding: 12px 20px 12px;
			z-index: 5;

			i {
				display: none;
			}
		}

		.text_img {
			width: 100vw;
			margin-left: -20px;
			order: 2;

			&:after {
				content: '';
				position: absolute;
				right: calc(50% + 8px);
				top: 0;
				display: inline-block;
				width: 100%;
				height: 16px;
				transform: skewX(-45deg);
				background-color: #fff;
			}

			&:before {
				content: '';
				position: absolute;
				left: calc(50% + 8px);
				top: 0;
				display: inline-block;
				width: 100%;
				height: 16px;
				transform: skewX(45deg);
				background-color: #fff;
			}

			img {
				position: static;
				max-width: 100%;
			}
		}
	}

	.text_left.p70 .text_wrap_content::after,
	.text_right.p70 .text_wrap_content::after {
		display: none;
	}

	.project_content {
		font-size: 14px;

		h2 {
			font-size: 18px;
		}

		h3 {
			font-size: 17px;
		}

		h4 {
			font-size: 16px;
		}

		img {
			margin-left: -20px;
			max-width: 100vw;
		}
	}

	.inspiration_nav {
		padding-top: 24px;
		padding-bottom: 16px;
		justify-content: center;
	}

	.inspiration_nav_link {
		padding-left: 16px;
		padding-right: 16px;
		border-radius: 18px;
		margin-bottom: 8px;
		margin-left: 4px;
		margin-right: 4px;
		color: #9c9c9e;
		background-color: #f5f5f5;
	}

	.inspiration_nav_link.active {
		background-color: #3453a8;
		color: #ffffff;
	}

	.inspiration_nav_link.active:after,
	.inspiration_nav_link.hover:after {
		display: none !important;
	}

	.inspiration_top .title,
	.inspiration_top .subtitle {
		br {
			display: none;
		}
	}

	.inspiration_case {
		padding: 20px;
		text-align: center;
		align-items: center;
	}

	.inspiration_case .text {
		display: none;
	}

	.inspiration_case .title {
		font-size: 20px !important;
	}

	.inspiration_case .link {
		padding: 8px 10px 9px;
		min-width: 120px;
		margin-top: 16px;
		background-color: rgba(134, 134, 134, 0.24);

		i {
			display: none;
		}
	}

	.inspiration_cta {
		flex-direction: column;
		padding-top: 0;
		padding-bottom: 24px;
	}

	.inspiration_cta_left {
		padding-right: 0;
		width: 100%;
	}

	.inspiration_cta_left .title {
		position: relative;
		width: 100vw;
		left: -20px;
		padding-top: 36px;
		padding-bottom: 36px;
		padding-left: 20px;
		padding-right: 20px;
		margin-bottom: 25px;
		background-image: url(../img/contact_title_bg.png);
		background-repeat: no-repeat;
		background-position: center bottom;
		-webkit-background-size: cover;
		background-size: cover;
		color: #fff;
		text-align: center;
		z-index: 1;
		font-size: 24px;
	}

	.inspiration_cta_left .text {
		display: none;
	}

	.inspiration_cta_right {
		padding-left: 0;
		width: 100%;
	}

	.header_menu {
		left: auto;
		right: 0;
		box-shadow: none;
		width: 100vw;
		height: calc(100vh - 55px) !important;
		opacity: 0;
		visibility: hidden;
		background-color: #302f36;
		background-image: linear-gradient(to top, #47454f, #302f36);
		box-shadow: none;
		transition: all 0.3s linear;

		&.menu_open {
			opacity: 1;
			visibility: visible;
		}
	}

	.header_menu .menu-item {
		margin-bottom: 12px;
	}

	.header_menu .menu-item:not(:last-of-type) {
		margin-right: 0;
	}

	.header_menu .menu {
		display: flex;
		flex-direction: column;
		align-items: flex-end;
		padding-right: 20px;
		padding-top: 100px;
	}

	.header_menu a {
		font-size: 20px;
		font-weight: 700;
		color: #fff;
	}

	.slider_contacts .title {
		font-size: 24px;
	}

	.top_slider_descr .text {
		font-size: 16px;
	}

	.slider_phones a {
		padding-left: 24px;
		font-size: 12px;
		background-size: 14px;
	}

	.p70 {
		&:after {
			content: '';
			display: block;
			height: 16px;
			width: 100%;
			background-color: #E4E4E4;
		}
	}

	.about_text .text:before {
		top: -5px;
		left: -40px;
		font-size: 100px;
		color: rgba(177, 177, 177, 0.2);
	}

	.top_slider_descr .btn {
		width: calc(50% - 3px);
	}

	.header_menu .current-menu-item a:after {
		transform: translate3d(-50%, 0, 0);
	}
}

@media screen and (max-width: 375px) {
	.product {
		&__paggination {
			ul {
				a {
					font-size: 16px;
				}

				span {
					font-size: 16px;

					&.current {
						border-bottom: 2px solid #3453a8;
					}
				}
			}
		}
	}
}

@media screen and (max-height: 440px) {
	.header_menu .menu {
		padding-top: 10px;
	}
}

/*================= END *media-queries =================*/