/*=================
footer
=================*/
.footer {
	background-image: linear-gradient(104deg, #47454f, #302f36);
	position: relative;
}

.footer_wrap {
	padding-top: 42px;
	display: flex;
	align-items: flex-start;
}

.footer_col {
	width: 24%;
	color: #fff;

	&:last-of-type {
		width: 200px;
		margin-left: auto;
	}

	.title {
		font-size: 30px;

		a {
			color: #fff;
			text-decoration: none;
		}
	}
}

.footer_menu {
	margin-top: 4px;
	padding-left: 0;
	list-style: none;

	li {
		font-size: 0;
		line-height: 1;
	}

	a {
		color: #fff;
		text-decoration: none;
		font-size: 16px;
		line-height: 1.5;
		letter-spacing: -0.4px;
		opacity: 0.6;
		font-family: Raleway, sans-serif;

		&:hover {
			text-decoration: underline;
		}
	}
}

.footer_top {
	padding-top: 19px;
	padding-bottom: 18px;
	border-top: 4px solid #47454f;
	background-color: #fff;
}

.footer_logo {}

.footer_phones {
	margin-top: 38px;
	margin-bottom: 4px;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	padding-left: 0;
	list-style: none;

	li {
		line-height: 0.8;
	}

	a {
		font-size: 14px;
		color: #ffffff;
		opacity: 0.6;
		text-decoration: none;
	}
}

.footer_email {
	color: #fff;
	opacity: 0.6;
	font-size: 14px;
	letter-spacing: 0.6px;
	text-decoration: none;

	&:hover {
		text-decoration: underline;
	}

	i {
		margin-right: 8px;
		font-size: 14px;
		vertical-align: middle;
	}
}

.footer_social {
	margin-top: 32px;
	list-style: none;
	padding-left: 0;
	display: flex;
	justify-content: space-between;

	li {
		display: inline-block;
	}

	a {
		position: relative;
		display: inline-block;
		width: 40px;
		height: 40px;
		border-radius: 50%;
		background-color: rgba(255, 255, 255, 0.02);
		text-decoration: none;
		transition: background-color 0.3s linear;

		&:hover {
			background-color: rgba(255, 255, 255, 0.2);
		}

		&:after {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			display: inline-block;
			width: 100%;
			height: 100%;
			background-repeat: no-repeat;
			background-position: center;
			opacity: 0.6;
		}
	}

	[href^='https://www.instagram.com']:after {
		background-image: url(../img/instagram.svg);
	}

	[href^='https://www.facebook.com']:after {
		background-image: url(../img/facebook.svg);
	}

	[href^='https://plus.google.com']:after {
		background-image: url(../img/google-plus.svg);
	}
}

.footer_bottom {
	margin-top: 4px;
	padding-top: 17px;
	padding-bottom: 17px;
	border-top: 1px solid rgba(255, 255, 255, 0.17);
	font-size: 10px;
	color: #fff;
	opacity: 0.6;
	text-align: center;

	p {
		margin: 0;
	}

	a {
		color: #fff;
		text-decoration: none;
	}
}

.to_top {
	position: absolute;
	right: 50px;
	top: -98px;
	width: 77px;
	height: 77px;
	padding: 10px;
	border-radius: 50%;
	background-color: #ee132b;
	color: #fff;
	text-align: center;
	cursor: pointer;
	transition: background-color 0.3s linear;

	&:hover {
		background-color: #f03d52;
	}

	i {
		font-size: 46px;
	}

	span {
		display: block;
		margin-top: -18px;
		text-transform: lowercase;
		font-size: 12px;
	}
}

/*=================
END footer
=================*/