@font-face {
	font-family: 'Avenir Next';
	src: url('../fonts/AvenirNext/AvenirNextCyr-ThinItalic.eot');
	src: local('Avenir Next Cyr Thin Italic'), local('AvenirNextCyr-ThinItalic'), url('../fonts/AvenirNext/AvenirNextCyr-ThinItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/AvenirNext/AvenirNextCyr-ThinItalic.woff') format('woff'), url('../fonts/AvenirNext/AvenirNextCyr-ThinItalic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: 'Avenir Next';
	src: url('../fonts/AvenirNext/AvenirNextCyr-Thin.eot');
	src: local('Avenir Next Cyr Thin'), local('AvenirNextCyr-Thin'), url('../fonts/AvenirNext/AvenirNextCyr-Thin.eot?#iefix') format('embedded-opentype'), url('../fonts/AvenirNext/AvenirNextCyr-Thin.woff') format('woff'), url('../fonts/AvenirNext/AvenirNextCyr-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir Next Cyr Ultra';
	src: url('../fonts/AvenirNext/AvenirNextCyr-UltraLightIt.eot');
	src: local('Avenir Next Cyr Ultra Light Italic'), local('AvenirNextCyr-UltraLightIt'), url('../fonts/AvenirNext/AvenirNextCyr-UltraLightIt.eot?#iefix') format('embedded-opentype'), url('../fonts/AvenirNext/AvenirNextCyr-UltraLightIt.woff') format('woff'), url('../fonts/AvenirNext/AvenirNextCyr-UltraLightIt.ttf') format('truetype');
	font-weight: 200;
	font-style: italic;
}

@font-face {
	font-family: 'Avenir Next Cyr Ultra';
	src: url('../fonts/AvenirNext/AvenirNextCyr-UltraLight.eot');
	src: local('Avenir Next Cyr Ultra Light'), local('AvenirNextCyr-UltraLight'), url('../fonts/AvenirNext/AvenirNextCyr-UltraLight.eot?#iefix') format('embedded-opentype'), url('../fonts/AvenirNext/AvenirNextCyr-UltraLight.woff') format('woff'), url('../fonts/AvenirNext/AvenirNextCyr-UltraLight.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir Next';
	src: url('../fonts/AvenirNext/AvenirNextCyr-LightItalic.eot');
	src: local('Avenir Next Cyr Light Italic'), local('AvenirNextCyr-LightItalic'), url('../fonts/AvenirNext/AvenirNextCyr-LightItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/AvenirNext/AvenirNextCyr-LightItalic.woff') format('woff'), url('../fonts/AvenirNext/AvenirNextCyr-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Avenir Next';
	src: url('../fonts/AvenirNext/AvenirNextCyr-Light.eot');
	src: local('Avenir Next Cyr Light'), local('AvenirNextCyr-Light'), url('../fonts/AvenirNext/AvenirNextCyr-Light.eot?#iefix') format('embedded-opentype'), url('../fonts/AvenirNext/AvenirNextCyr-Light.woff') format('woff'), url('../fonts/AvenirNext/AvenirNextCyr-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir Next';
	src: url('../fonts/AvenirNext/AvenirNextCyr-Italic.eot');
	src: local('Avenir Next Cyr Italic'), local('AvenirNextCyr-Italic'), url('../fonts/AvenirNext/AvenirNextCyr-Italic.eot?#iefix') format('embedded-opentype'), url('../fonts/AvenirNext/AvenirNextCyr-Italic.woff') format('woff'), url('../fonts/AvenirNext/AvenirNextCyr-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Avenir Next';
	src: url('../fonts/AvenirNext/AvenirNextCyr-Regular.eot');
	src: local('Avenir Next Cyr Regular'), local('AvenirNextCyr-Regular'), url('../fonts/AvenirNext/AvenirNextCyr-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/AvenirNext/AvenirNextCyr-Regular.woff') format('woff'), url('../fonts/AvenirNext/AvenirNextCyr-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir Next';
	src: url('../fonts/AvenirNext/AvenirNextCyr-MediumItalic.eot');
	src: local('Avenir Next Cyr Medium Italic'), local('AvenirNextCyr-MediumItalic'), url('../fonts/AvenirNext/AvenirNextCyr-MediumItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/AvenirNext/AvenirNextCyr-MediumItalic.woff') format('woff'), url('../fonts/AvenirNext/AvenirNextCyr-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Avenir Next';
	src: url('../fonts/AvenirNext/AvenirNextCyr-Medium.eot');
	src: local('Avenir Next Cyr Medium'), local('AvenirNextCyr-Medium'), url('../fonts/AvenirNext/AvenirNextCyr-Medium.eot?#iefix') format('embedded-opentype'), url('../fonts/AvenirNext/AvenirNextCyr-Medium.woff') format('woff'), url('../fonts/AvenirNext/AvenirNextCyr-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir Next';
	src: url('../fonts/AvenirNext/AvenirNextCyr-DemiItalic.eot');
	src: local('Avenir Next Cyr Demi Italic'), local('AvenirNextCyr-DemiItalic'), url('../fonts/AvenirNext/AvenirNextCyr-DemiItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/AvenirNext/AvenirNextCyr-DemiItalic.woff') format('woff'), url('../fonts/AvenirNext/AvenirNextCyr-DemiItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
}

@font-face {
	font-family: 'Avenir Next';
	src: url('../fonts/AvenirNext/AvenirNextCyr-Demi.eot');
	src: local('Avenir Next Cyr Demi'), local('AvenirNextCyr-Demi'), url('../fonts/AvenirNext/AvenirNextCyr-Demi.eot?#iefix') format('embedded-opentype'), url('../fonts/AvenirNext/AvenirNextCyr-Demi.woff') format('woff'), url('../fonts/AvenirNext/AvenirNextCyr-Demi.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir Next';
	src: url('../fonts/AvenirNext/AvenirNextCyr-BoldItalic.eot');
	src: local('Avenir Next Cyr Bold Italic'), local('AvenirNextCyr-BoldItalic'), url('../fonts/AvenirNext/AvenirNextCyr-BoldItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/AvenirNext/AvenirNextCyr-BoldItalic.woff') format('woff'), url('../fonts/AvenirNext/AvenirNextCyr-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Avenir Next';
	src: url('../fonts/AvenirNext/AvenirNextCyr-Bold.eot');
	src: local('Avenir Next Cyr Bold'), local('AvenirNextCyr-Bold'), url('../fonts/AvenirNext/AvenirNextCyr-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/AvenirNext/AvenirNextCyr-Bold.woff') format('woff'), url('../fonts/AvenirNext/AvenirNextCyr-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Avenir Next';
	src: url('../fonts/AvenirNext/AvenirNextCyr-HeavyItalic.eot');
	src: local('Avenir Next Cyr Heavy Italic'), local('AvenirNextCyr-HeavyItalic'), url('../fonts/AvenirNext/AvenirNextCyr-HeavyItalic.eot?#iefix') format('embedded-opentype'), url('../fonts/AvenirNext/AvenirNextCyr-HeavyItalic.woff') format('woff'), url('../fonts/AvenirNext/AvenirNextCyr-HeavyItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Avenir Next';
	src: url('../fonts/AvenirNext/AvenirNextCyr-Heavy.eot');
	src: local('Avenir Next Cyr Heavy'), local('AvenirNextCyr-Heavy'), url('../fonts/AvenirNext/AvenirNextCyr-Heavy.eot?#iefix') format('embedded-opentype'), url('../fonts/AvenirNext/AvenirNextCyr-Heavy.woff') format('woff'), url('../fonts/AvenirNext/AvenirNextCyr-Heavy.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Raleway';
	src: url('../fonts/Raleway/Raleway-Regular.woff2') format('woff2'), url('../fonts/Raleway/Raleway-Regular.woff') format('woff'), url('../fonts/Raleway/Raleway-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}